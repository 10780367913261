:root {
    --white: #ffffff;
    --black: #000000;
    --red: #EB3333;
    --button: #333333;
    --grey: #555555;
    --darkGrey: #4F4F4F;
    --yellow: #ff0;
    --blue: #9FD7FF;
}
  