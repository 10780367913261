.pagination .previous {
    display: none;
}

.pagination .next {
    display: none;
}

.pagination {
    display: grid;
    grid-template-columns: repeat(auto-fill, 20px);
    grid-gap: var(--size-grid-gap);
    padding: 50px 0 0;
}

.pagination li{
    font-size: var(--font-size-text-big);
    display: flex;
    text-align: center;
    color: var(--button);
    cursor: pointer;
    font-family: 'Mossport', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.pagination li a {
    background: var(--white);
    border: 2px solid #333333;
    box-sizing: border-box;
    padding: 10px;
    outline: none;
    min-width: 44px;
}

.pagination li.active {
    color: var(--red);
}

.pagination li.active a {
    border: 2px solid var(--red);
}

@media screen and (max-width: 700px) {
    .pagination {
        padding: 30px 0 0;
    }
}