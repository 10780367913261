.Container {
  /* display: grid; */
  /* grid-template-columns: 4fr 6fr;
    grid-gap: var(--size-grid-gap); */
}

.Container-MainPage {
  grid-template-areas:
    "a c"
    "b c";
}

.Container-AboutUsPage {
  grid-template-areas:
    "a a"
    "b c"
    "d c"
    "d c"
    "d c"
    "d c";
}

.Quote {
  grid-area: d;
  width: 100%;
  white-space: break-spaces;
  line-height: var(--line-height-name);
  font-size: var(--font-size-name);
  font-family: "Gotham Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
}

.Media-block {
  margin: 4vw 0;
  display: flex;
  flex-direction: column;
  gap: 4vw;
}

.Container img {
  width: 100%;
  height: auto;
}

.Text {
  font-size: var(--font-size-text-big);
  color: var(--black);
  line-height: 1.5;
  white-space: break-spaces;
  font-family: "Gotham Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: justify;
}

.Files {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.IconLink {
  margin-top: 20px;
  margin-right: 5px;
  max-width: 114px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  color: var(--black);
  line-height: 1.5;
  font-family: "Gotham Pro", sans-serif;
}

.Icon {
  width: 64px !important;
  margin-bottom: 10px;
}

.Image {
  grid-area: c;
}

.Video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Banner-container {
  width: 100%;
}

.Banner-container img {
  width: 100%;
}

@media screen and (max-width: 600px) {
  iframe {
    width: 100%;
    height: auto;
  }

  /* .Container {
        grid-template-columns: 1fr;
    } */

  .Container-MainPage {
    grid-template-areas:
      "a"
      "c"
      "b";
  }

  .Container-AboutUsPage {
    grid-template-areas:
      "a"
      "c"
      "b"
      "d";
  }

  .Quote {
    width: 100%;
  }

  .Text {
    text-align: left;
  }

  .IconLink {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    display: grid;
    grid-template-columns: 1fr 4fr;
    justify-items: flex-start;
    text-align: left;
    align-items: center;
  }

  .Icon {
    margin-bottom: 0;
    margin-right: 10px;
  }
}