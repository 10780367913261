.ActionTypes-Item {
    background: var(--white);
    border: none;
    border-bottom: 5px solid #EAEAEA;
    padding: var(--size-menu-padding);
    width: calc(280px + 4.9vw);
    cursor: pointer;
    color: var(--grey);
    text-transform: uppercase;
    font-size: var(--font-size-name);
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;

}

.ActionTypes {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: var(--size-element-padding);
}

.ActionTypes-Item_active {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-bottom: 5px solid #727272;
    color: var(--black);
}

@media screen and (max-width: 600px) {
    .ActionTypes {
        flex-direction: column;
    }
    .ActionTypes-Item {
        width: 100%;
    }
    
}