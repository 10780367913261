.ObjectCard-Image {
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
    object-fit: fill;
    max-height: 300px;
    min-height: 140px;
}

.OtherCard-ObjectImage {
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
    object-fit: fill;
    max-height: 150px;
}

.Object-Image--HideLink {
    width: 100% !important;
    height: auto !important;
    max-height: 60vh;
    object-fit: fill;
}

.Slide {
    width: 100% !important;
}

.ObjectCard-Link {
    color: var(--black);
    text-decoration: none;
}

.ObjectCard-ContentWrapper {
    margin: 10px;
}

.ObjectCard-Subtitle {
    font-size: var(--font-size-text-big);
    text-transform: uppercase;
    color: var(--black);
    line-height: 23px;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.OtherCard-Subtitle {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.OtherCard-Subtitle--HideLink {
    font-size: var(--font-size-name);
    margin-top: calc(-20px + 4.9vw);
}

.ObjectCard-Title {
    font-weight: bold;
    font-size: calc(15px + 0.6vw);
    color: var(--black);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    text-transform: uppercase;
}

.OtherCard-Title {
    font-size: var(--font-size-date);
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    text-transform: uppercase;
}

.OtherCard-Title--HideLink {
    font-size: var(--font-size-title) !important;
    line-height: 52px !important;
}

.ObjectCard-Adress {
    font-size: var(--font-size-text-small);
    line-height: 15px;
    color: var(--grey);
    margin: var(--size-element-card);
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.ObjectCard-Content {
    font-size: var(--font-size-text-big);
    line-height: 27px;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    white-space: break-spaces;
}

@media screen and (max-width: 800px) {
    .Object-Image {
        min-height: 200px;
    }
    .OtherCard-ObjectImage {
        width: 100%;
        height: auto;
        aspect-ratio: unset;
        object-fit: cover;
        max-height: 250px;
    }
    .ObjectCard-Title {
        line-height: 25px;
    }
    .OtherCard-Title--HideLink {
        line-height: 25px !important;
    }
    
}

@media screen and (min-width: 700px) {
    .ObjectCard-Title {
        line-height: 25px;
        font-size: calc(11px + 0.3vw);
    }
    
}

@media screen and (min-width: 1110px) {
    .ObjectCard-Title {
        line-height: 25px;
        font-size: calc(15px + 0.6vw);
    }
    
}
