.Text {
    color: var(--black);
    font-size: var(--font-size-text-big);
    padding: var(--size-element-padding);
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: var(--line-height);
    white-space: break-spaces;
}

.Container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr  1fr;
    grid-gap:var(--size-grid-gap);
}

.Text-With-Line {
    border-top: 1px solid #CDCDD0;
    padding: var(--size-elment-paragraph);
    margin-top: 50px;
}

@media screen and (max-width: 700px) {

    .Container {
        grid-template-columns: 1fr;
        grid-template-rows: none;
    }    

    .Text-With-Line {
        margin-top: 30px;
    }
}