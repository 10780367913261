.Swiper-Container {
    width: 90vw;
    padding: var(--size-element-padding);
}
 
.swiper-button-prev, .swiper-button-next {
    width: calc(28px/ 28 * 28);
    height: 28px;
    margin-top: calc(-1 * 22px / 2);
    color: var(--red);
    background: var(--white);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    outline: none;
}

.swiper-wrapper{
    -webkit-transform-style: preserve-3d;
}

.swiper-slide {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
}

.swiper-button-prev:after, .swiper-button-next:after {
    font-size: 14px;
}
 
@media screen and (max-width: 950px) {
    .Swiper-Container{
        width: 100vw;
    }
    .swiper-container {
        max-width: 100vw;
    }
    .swiper-slide img {
        width: 100%;
    }
}
 
.Container {
    padding: 0;
}