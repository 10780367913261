.Container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--size-grid-gap);
}

@media screen and (max-width: 600px) {
    .Container {
        grid-template-columns: 1fr;
    }
}