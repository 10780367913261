:root {
    --size-section-padding: 0 5% 50px;
    --size-menu-padding: 30px 5%;
    --size-element-padding: 0 0 50px 0;
    --size-elment-paragraph: 50px 0;
    --size-elment-paragraph-small: 30px 0;
    --size-element-margin: 0 0 30px 0;
    --size-element-card: 30px 0;
    --size-element-wrapper: 30px 0 50px;
    --size-grid-gap: calc(39px + -1.4vw) calc(21px + 1.4vw);
}
 
@media screen and (max-width: 700px) {
    :root {
        --size-section-padding: 0 20px 30px;
        --size-menu-padding: 20px;
        --size-element-padding: 0 0 30px 0;
        --size-elment-paragraph: 30px 0;
        --size-elment-paragraph-small: 20px 0;
        --size-element-wrapper: 20px 0 30px;
        --size-element-margin: 0 0 20px 0;
        --size-element-card: 20px 0;
    }
}

@media screen and (min-width: 1920px) {
    :root {
        --size-grid-gap: calc(21px + 1.4vw);
    }
}