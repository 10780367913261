.Container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--size-grid-gap);
}

.Description {
    grid-area: b;
}

.Description-Title {
    font-size: var(--font-size-text-big);
    padding: 15px 0;
    color: var(--black);
    line-height: var(--line-height);
    font-family: 'Gotham Pro', sans-serif;
}

.Description-Subtitle{
    font-size: var(--font-size-name);
    font-weight: bold;
    font-style: normal;
    font-family: 'Gotham Pro', sans-serif;
    line-height: var(--line-height-name);
}