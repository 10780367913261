.Container {
    display: grid;
    grid-template-columns: 8fr 2fr;
    grid-gap: var(--size-grid-gap);
}

.Container--VisuallyImpairedVersion {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--size-grid-gap);
}
 
.OtherCards-Wrapper {
    max-width: 250px;
    justify-self: right;
}

.OtherCards-Title {
    font-size: var(--font-size-title);
    color: var(--black);
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Mossport', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
}

@media screen and (max-width: 600px) {
    .Container {
        grid-template-columns: 1fr;
    }
    .OtherCards-Wrapper {
        max-width: 100%;
        justify-self: left;
    }
}