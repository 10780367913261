/* .Menu-Wrapper {
    width: 90vw;
    margin: 0 auto;
} */

.Menu {
    display: grid;
    /* grid-template-columns: minmax(20%,10%) minmax(80%,90%); */
    grid-template-columns: 1fr 6fr 2fr;
    align-items: center;
    grid-gap: var(--size-grid-gap);
    padding: var(--size-menu-padding);
    width: 90vw;
    margin: 0 auto;
}

.Menu--VisuallyImpairedVersion {
    display: grid;
    grid-template-columns: 35% 65%;
    align-items: center;
    padding: var(--size-menu-padding);
    width: 100%;
    margin: 0 auto;
}

.Menu-Burger {
    width: 20px;
    height: 20px;
    color: var(--red);
}

.Container {
    min-height: 100vh;
} 

.Main-MossIcon {
    justify-self: end;
}

.Main-MossIcon img {
    width: auto;
    height: 70px;
    vertical-align: bottom;  
}

.Underline--VisuallyImpairedVersion {
    text-decoration: underline;
}

@media screen and (max-width: 700px) {
    .Menu-Wrapper {
        width: 100vw;
    }
    .Menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Menu--VisuallyImpairedVersion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    } 
    .Main-MossIcon {
        padding-left: 20px;
    }
}