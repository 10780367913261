@font-face {
    font-family: 'Mossport';
    src: url('../../../../fonts/Mossport.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.Title{
    text-transform: uppercase;
    padding: var(--size-element-padding);
    font-size: var(--font-size-title);
    line-height: var(--line-height-title);
    font-family: 'Mossport', sans-serif;
    font-style: normal;
    font-weight: normal;
}
