.NewsCard {
    display: flex;
    flex-direction: column;
}

.Slide {
 width: 100% !important;
}

.NewsCard-Image {
    display: block;
    width: 100%;
    height: 180px;
    object-fit: fill;
}

.NewsCard-Image--HideLink {
    width: 100% !important;
    height: auto !important;
    max-height: 60vh;
    object-fit: fill;
}

.Slide-Link {
    color: var(--black);
    text-decoration: none;
}

.Slide-Link--OtherNews {
    display: block;
    color: var(--black);
    padding: var(--size-elment-paragraph-small);
    border-bottom: 1px solid #CCCCCC;
    text-decoration: none;
}

.Slide-Link--OtherNews:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.Slide-Text {
    margin: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.Title {
    margin: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-transform: uppercase;
    height: 60px;
    font-size: var(--font-size-text-big);
    -webkit-line-clamp: 3;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
}

.Title--VisuallyImpairedVersion {
    margin: 20px 0;
    text-transform: uppercase;
}

.Title--OtherNews {
    font-weight: bold;
    font-size: var(--font-size-text-small);
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 70px;
    text-transform: uppercase;
    line-height: 23px;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
}

.Title--HideLink {
    color: var(--black);
    font-weight: bold;
    text-transform: uppercase;
    font-size: var(--font-size-sub-title);
    margin: calc(13px + 1.2vw) 0;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    line-height: calc(14px + 1.2vw);
}

.Subtitle {
    height: 100px;
    font-size: var(--font-size-text-small);
    line-height: 20px;
    -webkit-line-clamp: 5;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.Subtitle--OtherNews {
    font-size: var(--font-size-text-small);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 100px;
    line-height: 20px;
    -webkit-line-clamp: 5;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.Subtitle--HideLink {
    color: var(--black);
    font-size: var(--font-size-text-big);
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: calc(15px + 0.8vw);
    white-space: break-spaces;
}

@media screen and (max-width: 700px) {
    .Title {
        margin: 10px;
    }
    .Slide-Text {
        margin: 10px;
    }
}
