.Container {
    background: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
}

.Container:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.Text {
    font-size: var(--font-size-text-small);
    line-height: 20px;
    color: var(--black);
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
}