:root {
    --font-size-text-tiny: 14px;
    --font-size-text-small: 16px;
    --font-size-title: calc(17px + 2.2vw);       /* 48px */
    --font-size-text-big: calc(19px + -0.2vw);   /* 18px */
    --font-size-date: 20px;
    --font-size-name: calc(16px + 0.5vw);        /* 24px */
    --font-size-sub-title: calc(16px + 1.1vw);   /* 32px */
    --line-height: calc(19px + 0.2vw);           /* 22px */
    --line-height-title: calc(29px + 1.0vw);     /* 48px */
    --line-height-name: calc(22px + 0.4vw);      /* 30px */
}

@media screen and (min-width: 1920px) {
    :root {
        --font-size-text-small: 16px;
        --font-size-title: 48px; 
        --font-size-text-big: 18px; 
        --font-size-date: 20px;
        --font-size-name: 24px;
        --font-size-sub-title: 32px;
        --line-height: 22px;
        --line-height-title: 48px;
        --line-height-name: 30px;
    }
}