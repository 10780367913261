.Wrapper {
    display: flex;
    width: 100% !important;
}

.ForVisuallyImpaired--Menu {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-items: center;
    align-items: center;
    margin-top: 5px;
    padding: 30px 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    width: 100%;
}

.ForVisuallyImpaired--Nav-Item {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.Button {
    display: inline-block;
    width: 80%;
    padding: 8px 12px;
    border: 3px solid;  
    cursor: pointer;
}

.ForVisuallyImpaired--Color-Item {
    padding: 5px;
    border: 1px solid var(--grey);
    cursor: pointer;
    text-decoration: none;
    box-sizing: border-box;
    margin-left: 5px;
}

.ForVisuallyImpaired--FontSize-Item {
    cursor: pointer;
    margin-left: 5px;
    text-decoration: underline;
}

@media screen and (max-width: 700px) {
    .ForVisuallyImpaired--Menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 45vh;
    }
}