@font-face {
  font-family: "Mossport";
  src: url("../../../fonts/Mossport.eot");
  src: url("../../../fonts/Mossport.eot?#iefix") format("embedded-opentype"),
    url("../../../fonts/Mossport.woff") format("woff"),
    url("../../../fonts/Mossport.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../../fonts/GothamPro.eot");
  src: url("../../../fonts/GothamPro.eot?#iefix") format("embedded-opentype"),
    url("../../../fonts/GothamPro.woff") format("woff"),
    url("../../../fonts/GothamPro.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@import "../../../constans/colors.css";
@import "../../../constans/font-sizes.css";
@import "../../../constans/elementSizes.css";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  max-width: 1920px;
}

html,
body,
#root {
  width: 100%;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
}

li {
  list-style: none;
}

#root {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

section {
  padding: var(--size-section-padding);
  width: 90vw;
  margin: 0 auto;
}

button {
  outline: none;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App--VisuallyImpairedVersion * {
  font-family: none !important;
  box-shadow: none !important;
  max-width: 100%;
}

.App--VisuallyImpairedVersion * img {
  display: none;
}

.White-Black--Theme
  *
  :not(.Black-White--Theme-ColorEl):not(.Black-Yellow--Theme-ColorEl):not(.Blue-Black--Theme-ColorEl) {
  background: var(--white) !important;
  color: var(--black) !important;
}
.White-Black--Theme-ColorEl {
  background: var(--white) !important;
  color: var(--black) !important;
}

.Black-White--Theme
  *
  :not(.White-Black--Theme-ColorEl):not(.Black-Yellow--Theme-ColorEl):not(.Blue-Black--Theme-ColorEl) {
  background: var(--black) !important;
  color: var(--white) !important;
}
.Black-White--Theme-ColorEl {
  background: var(--black) !important;
  color: var(--white) !important;
}

.Black-Yellow--Theme
  *
  :not(.White-Black--Theme-ColorEl):not(.Black-White--Theme-ColorEl):not(.Blue-Black--Theme-ColorEl) {
  background: var(--black) !important;
  color: var(--yellow) !important;
}
.Black-Yellow--Theme-ColorEl {
  background: var(--black) !important;
  color: var(--yellow) !important;
}

.Blue-Black--Theme
  *
  :not(.White-Black--Theme-ColorEl):not(.Black-White--Theme-ColorEl):not(.Black-Yellow--Theme-ColorEl) {
  background: var(--blue) !important;
  color: var(--black) !important;
}
.Blue-Black--Theme-ColorEl {
  background: var(--blue) !important;
  color: var(--black) !important;
}

.Small--FontSize
  *
  :not(.Medium--FontSize-FontSizeEl):not(.Big--FontSize-FontSizeEl) {
  font-size: 20px !important;
  line-height: 28px !important;
}
.Small--FontSize-FontSizeEl {
  font-size: 20px !important;
  line-height: 28px !important;
}
.Small--FontSize * h3 {
  font-size: 28px !important;
  line-height: 28px !important;
}

.Medium--FontSize
  *
  :not(.Small--FontSize-FontSizeEl):not(.Big--FontSize-FontSizeEl) {
  font-size: 24px !important;
  line-height: 36px !important;
}
.Medium--FontSize-FontSizeEl {
  font-size: 24px !important;
  line-height: 36px !important;
}
.Medium--FontSize * h3 {
  font-size: 36px !important;
  line-height: 36px !important;
}

.Big--FontSize
  *
  :not(.Small--FontSize-FontSizeEl):not(.Medium--FontSize-FontSizeEl) {
  font-size: 30px !important;
  line-height: 46px !important;
}
.Big--FontSize-FontSizeEl {
  font-size: 30px !important;
  line-height: 46px !important;
}
.Big--FontSize * h3 {
  font-size: 44px !important;
  line-height: 44px !important;
}

.Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cls-1 {
  fill: #eb3333;
}

.loader {
  position: absolute;
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--red);
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 2s linear infinite;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 40vh auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 700px) {
  .loader {
    width: 30px;
    height: 30px;
    margin: 20vh auto;
  }
}
