.Container {
    display: grid;
    grid-template-columns: 6fr 4fr;
    grid-gap: calc(21px + 1.4vw);
    grid-template-areas: 'a b'
                         'a c'
                         'd .';
}

.Container--VisuallyImpairedVersion {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: calc(21px + 1.4vw);
    grid-template-areas:
        'b'
        'c';
}
 
.Map {
    grid-area: a;
}
 
.SupportedBy {
    grid-area: b;
}
 
.Contacts {
    grid-area: c;
}

.Feedback {
    grid-area: d;
}

.Subtitle {
    text-transform: uppercase;
    color: var(--black);
    font-family: 'Mossport', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.Subtitle-SupportedBy {
    padding-bottom: 10px;
    font-size: var(--font-size-name);
}

.SupportedBy img {
    height: 70px;
    width: auto;

}

.Subtitle-Contacts {
    padding-bottom: 40px;
    font-size: var(--font-size-sub-title);
}

.Contacts-Item {
    padding-bottom: 35px;
    color: var(--black);
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
}

.Contacts-Item--Work-Time {
    white-space: break-spaces;
}

.Contacts-Item:last-child {
    padding-bottom: 0;
}

.Contacts-Item--Subtitle {
    padding-bottom: 10px;
    font-size: var(--font-size-text-small);
    color: var(--darkGrey);
    line-height: 15px;
}

@media screen and (max-width: 600px) {
    .Container {
        grid-template-columns: 1fr;
        grid-template-areas: 'c'
                             'b'
                             'a'
                             'd';
    }
}