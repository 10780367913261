.Container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: calc(11px + 4.8vw);;
}

.Container--VisuallyImpairedVersion {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: calc(11px + 4.8vw);;
}

@media screen and (max-width: 700px) {
    .Container {
        grid-template-columns: 1fr 1fr;
    }    
}

@media screen and (max-width: 500px) {
    .Container {
        grid-template-columns: 1fr;
    }    
}