.Footer {
    background-color: var(--red);
    margin-top: auto;
    width: 100vw;
}

.Footer-Container {
    display: grid;
    grid-template-columns: minmax(40%,60%) minmax(60%,40%);;
    padding: var(--size-menu-padding);
    align-items: end;
    width: 90vw;
    margin: 0 auto;
}

.Button {
    margin-top: 30px;
    background-color: var(--button);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: var(--white);
    padding: 10px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
}

.Copyright {
    font-size: var(--font-size-text-small);
    line-height: 20px;
    text-align: right;
    color: var(--white);
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.Copyright--VisuallyImpairedVersion {
    text-align: left;
    width: 100%;
    margin: 0 auto;
    border-top: 2px solid;
    padding: 30px 0;
}

@media screen and (max-width: 768px) {
    .Footer-Container {
        grid-template-columns: 1fr;
        width: 100vw;
    }
    .Button{
        text-align: left;
        margin: 30px 0;
    }
    .Copyright {
        text-align: left;
        max-width: 70%;
    }
}