.FileName {
    font-size: var(--font-size-text-big);
    text-transform: uppercase;
    color: var(--black);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.FileText {
    width: 100%;
    max-height: 60px;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 17px;
}

.Column {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Column .FileText {
    text-align: center;
    padding-top: 10px;
}

.Purchase {
    align-items: center;
    margin: 0 auto;
}