.Container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: calc(-8px + 5.4vw);
    width: 100%;
}

.Container-VisuallyImpairedVersion {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: calc(-8px + 5.4vw);
    width: 100%;
}
 
@media screen and (max-width: 600px) {
    .Container {
        grid-template-columns: 1fr;
    }
}