@import "../../../../../../../constans/colors.css";

.Feedback-Form {
    display: grid;
    grid-gap: 20px;
}

.Feedback-Form-Item {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.Feedback-Form-Input {
    height: 35px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--black);
    width: 100%;
    font-family: 'Gotham Pro', sans-serif;
    font-size: var(--font-size-text-small);
}

.Feedback-Form-Textarea {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--black);
    width: 100%;
    font-family: 'Gotham Pro', sans-serif;
    font-size: var(--font-size-text-small);
}

.Feedback-Form-Input--Checkbox {
    width: 25px !important;
}

.Feedback-Form-Label {
    margin-right: 10px;
    width: fit-content;
    font-family: 'Gotham Pro', sans-serif;
    color: var(--darkGrey);
}

.Feedback-Form-FileName {
    margin: 5px;
    width: fit-content;
    font-family: 'Gotham Pro', sans-serif;
    color: var(--darkGrey);
    font-size: var(--font-size-text-tiny);
    cursor: not-allowed;
}

.Feedback-Form-FileUpload {
    color: transparent;
    overflow: hidden;
    width: 149px !important;
}

.Feedback-Form-Validation {
    width: fit-content;
    font-family: 'Gotham Pro', sans-serif;
    color: var(--red);
    font-size: var(--font-size-text-tiny);
}

.Feedback-From-Button {
    height: 35px;
    width: fit-content;
    padding: 5px;
    border: 1px solid var(--black);
    background: var(--white);
    color: var(--black);
    font-family: 'Gotham Pro', sans-serif;
    border-radius: 5px;
    font-size: var(--font-size-text-small);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}