.Baner {
    display: block;
    width: 90%;
    margin: 0 auto;
    height: 550px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.Baner-Content{
    position: absolute;
    background-color: rgba(256, 256, 256, .8);
    width: 350px;
    padding: 10px 10px 20px;
    text-align: right;
    top:50px;
    right: 70px;
    white-space: break-spaces;
}

.Baner-ObjectSubtitile{
    font-size: var(--font-size-text-small);
    line-height: 22px;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    color: var(--black);
}

.Baner-ObjectTitile {
    font-size: var(--font-size-title);
    line-height: var(--line-height-title);
    font-family: 'Mossport', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    color: var(--black);
}

.Baner-ObjectItem{
    font-size: 12px;
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    color: var(--black);
    line-height: 14px;
    padding-top: 15px;
}

@media screen and (max-width: 1150px) {
    .Baner{
        height: 450px;
    }
}

@media screen and (max-width: 950px) {
    .Baner{
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .Baner{
        height: 350px;
    }
    .Baner-Content{
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .Baner{
        height: 230px;
    }
}