.NavigationList {
    display: flex;
}

.Column {
    flex-direction: column;
    padding: var(--size-menu-padding);
}

.Column .Navbar-Item {
    padding-bottom: 30px;
}

.Column .Link-Undecorated:last-child .Navbar-Item{
    padding-bottom: 0;
}

.Row {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Link-Undecorated {
    color: var(--black);
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 15px;
    font-size: var(--font-size-text-small);
    text-decoration: none;
    cursor: pointer;
}

.Link-Undecorated:hover {
    color: var(--red);
}

.Link-Active {
    color: var(--red);
}

/* .Footer-Navigation {
    display: grid;
    grid-gap: var(--size-menu-padding);
} */

.Footer-Navigation--Column-3 {
    display: grid;
    grid-gap: var(--size-menu-padding);
    grid-template-columns: repeat(3, 1fr);
}

.Footer-Navigation--Column-4 {
    display: grid;
    grid-gap: var(--size-menu-padding);
    grid-template-columns: repeat(4, 1fr);
}

.Footer-Navigation--Column-3 .Link-Undecorated {
    color: var(--white);
    font-weight: bold;
}

.Footer-Navigation--Column-4 .Link-Undecorated {
    color: var(--white);
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .Footer-Navigation--Column-4  {
        grid-template-columns: 1fr;
    }
    .Footer-Navigation--Column-3 {
        grid-template-columns: 1fr;
    }
}
