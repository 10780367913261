.Container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--size-grid-gap);
}

.Container--VisuallyImpairedVersion {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--size-grid-gap);
}

@media screen and (max-width: 700px) {
    .Container {
        grid-template-columns: 1fr 1fr;
    }    
}

@media screen and (max-width: 450px) {
    .Container {
        grid-template-columns: 1fr;
    }    
}