.Modal {
  max-width: 750px;
  width: 100%;
  height: auto;
  background: var(--white);
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  outline: none;
  margin: 0 auto;
}

.Modal-Overlay {
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  padding: 20px;
}

.Container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-gap: var(--size-grid-gap);
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 600px) {
  .Container {
    grid-template-columns: repeat(auto-fit, 150px);
  }
}
