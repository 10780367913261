.Text {
    padding: var(--size-element-padding);
    font-family: 'Gotham Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size-text-big);
    line-height: var(--line-height);
    color: var(--black);
    white-space: break-spaces;
}

.Container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: var(--size-grid-gap);
}

@media screen and (max-width: 800px) {
    .Container {
        grid-template-columns: 1fr 1fr;
    }
}